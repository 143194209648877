<template>
  <div>
    <div class="modal_header">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple">
            <div class="title_h">
              {{ $t("message.new_partner_clinic") }}
            </div>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24">
          <div class="grid-content bg-purple-light">
            <el-row class="button_r">
              <el-button
                type="success"
                :loading="loadingButton"
                :disabled="loadingButton ? true : false"
                @click="submit"
                >{{ $t("message.save_and_close") }}</el-button
              >
              <el-button @click="resetForm('form')">{{
                $t("message.close")
              }}</el-button>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'right'"  class="aticler_m" >

      <el-row :gutter="20">
        <el-col :span="10">
          <el-form-item :label="columns.name.title" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.phone_number.title" prop="phone_number">
            <el-input
                v-model="form.phone_number"
                v-mask="'+999999999999'"
                placeholder="998(__) ___-__-__"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="10">
          <el-form-item :label="columns.partner_clinic_id.title" prop="partner_clinic_id">
            <el-select
              class="autoInput"
              v-model="form.partner_clinic_id"
              filterable
              clearable
              :placeholder="$t('message.partner_clinic')"
            >
              <el-option
                v-for="item in partnerClinics"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import form from "@/utils/mixins/form";

export default {
  mixins: [form],

  data() {
    return {
      data: [],
      defaultProps: {
        children: "children",
        label: "label",
        price: "price",
      },
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "couriers/rules",
      model: "couriers/model",
      columns: "couriers/columns",
      partnerClinics: 'partnerClinic/inventory_all',
    }),
  },
  mounted() {
    if (this.partnerClinics && this.partnerClinics.length === 0)
      this.loadPartnerClinic();
  },
  methods: {
    ...mapActions({
      save: "couriers/store",
      loadPartnerClinic: "partnerClinic/inventoryAll",
    }),
    handleNodeClick(node, data, value) {
      this.services = $.grep(this.services, function (e) {
        return e.id != data.data.id;
      });
      this.services.push(data.data);
    },
    filterNode(value, data) {
      if (!value) return true;
      let dd = data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
      return dd;
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.save(this.form)
            .then((res) => {
              this.loadingButton = false;
              this.$notify({
                title: "Успешно",
                type: "success",
                offset: 130,
                message: res.message,
              });
              this.parent().listChanged();
              this.resetForm("form");
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$notify({
                title: "Ошибка",
                type: "error",
                offset: 130,
                message: err.error.message,
              });
            });
        }
      });
    },
    closeDrawerPatientService(obj) {
      if (obj.drawer) {
        this[obj.drawer] = false;
      }
    },
    handlePreview(file) {
      console.log(file);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style>
.my-kh input,
.my-kh {
  width: 200px !important;
  /* height: 32px  !important;
  line-height: 32px  !important; */
  margin-right: 7px !important;
  padding-right: 7px !important;
  display: block !important;
}
.filter-tree .custom-tree-node {
  width: 96%;
  display: inline-table !important;
}

.filter-tree .el-tree-node__content .aaa {
  display: flex;
}
.filter-tree .el-tree-node__content {
  height: 40px;
}
</style>